<template>
  <div class="applicants-list">
    <div class="options-bar">
      <ApplicantsListNavigation @filter="filterApplicants"/>
    </div>
    <Loading v-if="isLoading" />
    <ApplicantsListTable v-if="!isLoading" :applicants="applicants" />
  </div>
</template>

<script>
import ApplicantsListNavigation from "./components/ApplicantsListNavigation/Index.vue";
import ApplicantsListTable from "./components/ApplicantsListTable/Index.vue";
import Loading from "../../components/Loading.vue";

export default {
  name: "ApplicantsList",
  components: {
    ApplicantsListNavigation,
    ApplicantsListTable,
    Loading,
  },
  data() {
    return {
      applicants: [],
      photo: null,
      isLoading: false,
      infoComplete: false,
    };
  },
  async created() {
    this.getFreelancer();
  },
  mounted() {
    if (!this.$route.params.queue) {
      this.$router.push({
        params: { queue: "my-queue" },
      });
    }
  },
  methods: {
    async getFreelancer() {
      this.isLoading = true;
      const urlPhotos = [];
      const applicants = await this.$store.dispatch("evaluator/getFreelancer");
      for (const applicant of applicants) {
        urlPhotos.push(
          this.$store.dispatch("freelancer/getPhoto", applicant.user.photo),
        );
      }
      this.applicants = await this.getPhoto(urlPhotos, applicants);
      this.isLoading = false;
      this.infoComplete = true;
    },
    async getPhoto(arrayPhotos, applicants) {
      const applicantsArray = await Promise.all(arrayPhotos)
        .then((response) => {
          for (let i = 0; i < response.length; i += 1) {
            applicants[i].user.photo = response[i];
          }
          return applicants;
        })
        .catch((error) => console.log(error));
      return applicantsArray;
    },
    async filterApplicants(value) {
      this.applicants = value;
      const urlPhotos = [];
      for (const applicant of this.applicants) {
        urlPhotos.push(
          this.$store.dispatch("freelancer/getPhoto", applicant.user.photo),
        );
      }
      this.applicants = await this.getPhoto(urlPhotos, this.applicants);
    },
  },
};
</script>

<style lang="scss" scoped>
.options-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  width: 90%;
  margin: 2rem 0;
}
.applicants-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
