<template>
  <table class="applicants-table">
    <thead class="applicants-table__head">
      <tr>
        <th class="applicants-table__th">NAME</th>
        <th class="applicants-table__th">EMAIL</th>
        <th class="applicants-table__th">CITIZENSHIP</th>
      </tr>
    </thead>
    <tbody>
      <section v-if="applicants.length === 0" style="padding: 15px">
        <td> No data found to display. </td>
      </section>
      <tr
        class="applicants-table__tr"
        v-for="applicant in applicants"
        :key="applicant.user.id"
        @click="rowClick(applicant.user.id)"
      >
        <td class="applicants-table__td applicants-table__td--picture-and-name">
          <img
            :src="applicant.user.photo"
            alt="applicant picture"
            class="applicants-table__applicant-picture"
          />
          <span
            v-html="
              `${applicant.user.first_name} ${applicant.user.second_name} ${applicant.user.last_name}`
            "
          />
        </td>
        <td class="applicants-table__td" v-html="applicant.user.email"></td>
        <td
          class="applicants-table__td"
          v-html="applicant.user.citizenship"
        ></td>
        <!-- <td class="applicants-table__td" v-html="applicant.status"></td> -->
        <td class="applicants-table__td" v-html="applicant.evaluation"></td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "ApplicantsTable",
  props: {
    applicants: {
      type: Array,
    },
  },
  data() {
    return {
      infoComplete: false,
    };
  },
  methods: {
    rowClick(userId) {
      this.$router.push({ path: `/applicant/evaluator/${userId}` });
    },
  },
};
</script>

<style lang="scss" scoped>
$background-color: white;
.applicants-table {
  border-spacing: 0;
  border: 1px none #ccc;
  border-radius: $border-radius-md;
  box-shadow: $box-shadow;
  width: 70rem;
  background-color: $background-color;
  &__head {
    height: 4rem;
    padding: 0 1rem 0 1.5rem;
  }
  &__tr {
    @extend %anim-transition;
    &:hover {
      cursor: pointer;
      border-radius: $border-radius-md;
      transform: scale(1.002) translateY(-2px);
      box-shadow: $box-shadow;
      position: relative;
      z-index: 1;
    }
  }
  &__td {
    padding: 1rem 1rem 1rem 1.5rem;
    background-color: $background-color;
    &:first-child {
      border-radius: $border-radius-md 0 0 $border-radius-md;
    }
    &:last-child {
      border-radius: 0 $border-radius-md $border-radius-md 0;
    }
    &--picture-and-name {
      display: flex;
      align-items: center;
      font-weight: 600;
    }
    &--actions {
      text-align: center;
      width: auto;
    }
  }
  &__th {
    padding: 0 1rem 0 1.5rem;
    text-align: left;
    font-weight: 900;
    color: #666666;
    &--actions {
      text-align: center;
    }
  }
  &__applicant-picture {
    width: 4rem;
    height: 4rem;
    border-radius: 25%;
    margin-right: 1rem;
  }
  &__action-button {
    @include secondary-button;
  }
}
</style>
