<template>
  <nav class="applicants-list-navigation">
    <el-radio-group v-model="parametersOptions">
      <el-radio
        v-for="(item, index) in queriesOptions"
        :key="index"
        :to="item.to"
        :label="item.value"
        size="medium"
        @change="rowClickFilter(item.value)"
        >{{ item.label }}</el-radio
      >
    </el-radio-group>
  </nav>
</template>

<script>
import listNavigationOptions from '../../../../utils/listNavigationOptions';

export default {
  name: 'ApplicantsListNavigation',
  data() {
    return {
      applicants: [],
      parametersOptions: '',
      queriesOptions: listNavigationOptions,
    };
  },
  methods: {
    async rowClickFilter(item) {
      const payload = {};
      payload.evaluation = item;
      this.applicants = await this.$store.dispatch(
        'evaluator/getFreelancer',
        payload,
      );
      this.$emit('filter', this.applicants);
    },
  },
};
</script>

<style lang="scss" scoped>
.brains-navigation {
  height: 5rem;
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  box-shadow: $box-shadow, 0 1px 200px rgba($primary-color, 0.3);
  background-color: white;
  &__logo {
    height: 3rem;
    margin: 0 1.8rem;
  }
  &__profile-avatar-wrapper {
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    margin: 0 1rem;
    background-color: gray;
  }
}
.applicants-list-navigation {
  width: 80em;
  height: 3rem;
  background: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-shadow: $box-shadow;
  border-radius: $border-radius-md;
  &__ul {
    list-style: none;
    padding: 0;
    margin: 1.5rem 1.5rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 0.5rem;
  }
  &__a {
    color: rgb(94, 94, 94);
    text-decoration: none;
    font-weight: bold;
    padding: 0.7rem 1rem;
    border-radius: $border-radius-md;
    @extend %anim-transition;
    &:hover {
      background-color: rgb(226, 226, 226);
    }
  }
}
::v-deep {
  & .el-radio {
    &:hover {
      background: linear-gradient(
        to right,
        $primary-color,
        lighten($primary-color, 5%),
        lighten($primary-color, 15%)
      );
      box-shadow: $box-shadow;
      color: white;
      padding: 8px;
      border-radius: 10px;
    }
  }
  & .el-radio__label {
    font-size: 15px;
    padding-left: 5px;
    font-weight: 700;
  }
  & .el-radio__input.is-checked + .el-radio__label {
    color: rgb(94, 94, 94);
  }
  & .el-radio__input.is-checked .el-radio__inner {
    border-color: $primary-color-lighter;
    background: $primary-color-lighter;
  }
}
.router-link-exact-active {
  //background-color: $primary-color;
  background: linear-gradient(
    to right,
    $primary-color,
    lighten($primary-color, 5%),
    lighten($primary-color, 15%)
  );
  box-shadow: $box-shadow;
  color: white;
}
</style>
